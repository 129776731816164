'use client';

import type { ToastOptions, ToasterProps } from 'react-hot-toast';
import toast, { Toaster } from 'react-hot-toast';
import { IconWrapper } from '../icon';

export const ToastInitializer = ({ position = 'bottom-center', ...props }: ToasterProps) => {
	return <Toaster position={position} {...props} />;
};

const defaultConfig: ToastOptions = {};

const className = 'text-sm dark:!bg-background-100 !text-neutrals-text-primary border-l-2';

const success = (message: string, config: ToastOptions) => {
	return toast(message, {
		...defaultConfig,
		className: `${className} border-green`,
		icon: (
			<IconWrapper color="white" className="p-[2px]" size={'sm'} backgroundColor="green" name="Check" rounded />
		),
		duration: 2000,
		...config,
	});
};
const error = (message: string, config: ToastOptions) => {
	return toast(message, {
		...defaultConfig,
		className: `${className} border-red`,
		icon: <IconWrapper color="white" className="p-[2px]" size={'sm'} backgroundColor="red" name="Cross" rounded />,
		duration: 3000,
		...config,
	});
};

export const notify = Object.assign(toast, {
	success,
	error,
	remove: toast.remove,
});
